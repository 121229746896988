import React, { Component } from "react";
import styled from "styled-components";

export const StyledInput = styled.div`
  position: relative;
  width: 100%;
`;

class Input extends Component {
  isNumeric(value) {
    return !isNaN(value - parseFloat(value));
  }

  render() {
    const {
      type,
      style,
      name,
      label,
      onChange,
      onSubmit,
      value,
      placeholder,
      readOnly,
      error,
      required,
    } = this.props;
    return (
      <StyledInput className={type} style={style} error={error}>
        <input
          type={type}
          id={name}
          name={name}
          value={value}
          placeholder={placeholder}
          readOnly={readOnly}
          required={required}
          onChange={(e) => {
            const re = /^[0-9\b]+$/;
            if (type === "number" && this.isNumeric(e.target.value)) {
              onChange(name, parseInt(e.target.value));
            } else if (type !== "number") {
              onChange(name, e.target.value);
            }
          }}
        />
        {type === "number" ? (
          <div
            className="input-control"
            onClick={() => {
              onChange(name, value - 1);
            }}
          >
            <svg
              fill="#000000"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M19 13H5v-2h14v2z" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </div>
        ) : null}
        {type === "number" ? (
          <div
            className="input-control right"
            onClick={() => {
              onChange(name, value + 1);
            }}
          >
            <svg
              fill="#000000"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </div>
        ) : null}
      </StyledInput>
    );
  }
}

export default Input;
