import React from "react";

import { Flex, Text, useTheme } from "../styled";

import logo from "../images/img-main-stage.jpg";

export default ({
  title,
  message,
  type = "secondary",
  icon = null,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <Flex
      w="100%"
      px={30}
      py={15}
      borderRadius={8}
      bg={`${type}:setAlpha:0.1`}
      flexCenter
      relative
      row
      {...rest}
    >
      {React.cloneElement(icon, {
        color: theme.colors[type],
        style: { marginRight: 10 },
      })}
      <Flex>
        {title ? (
          <Text font="h5" mb={5} color={type}>
            {title}
          </Text>
        ) : null}
        <Text color={type}>{message}</Text>
      </Flex>
    </Flex>
  );
};
