import React from "react";
import { ShieldCheck } from "tabler-icons-react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Wrapper from "../components/wrapper";
import Form from "../components/form";
import Banner from "../components/banner";

import { Flex, Text, useTheme } from "../styled";

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Kontakt" keywords={[`gatsby`, `application`, `react`]} />
      <Wrapper maxWidth={800} py="5rem">
        <Flex>
          <Text font="h3" as="h3" color="secondary">
            Kontaktformular
          </Text>
          <p>
            Nutzen Sie dieses Formular, um Ihnen und uns den Erstkontakt so
            einfach wie möglich zu gestalten. Wir antworten Ihnen schnellst
            möglich.
          </p>

          <Flex bg="surface" mt={30} p={[20, 30]}>
            <Banner
              type="success"
              icon={<ShieldCheck size={28} />}
              message="Ihre Daten werden sicher per SSL übertragen."
            />
            <Form
              schema={[
                {
                  name: "firstname",
                  label: "Vorname",
                  type: "text",
                  required: true,
                },
                {
                  name: "lastname",
                  label: "Nachname",
                  type: "text",
                  required: true,
                },

                {
                  name: "street",
                  label: "Straße, Hausnummer",
                  type: "text",
                  required: false,
                },
                {
                  name: "address",
                  label: "Wohnort, PLZ",
                  type: "text",
                  required: false,
                },
                {
                  name: "email",
                  label: "E-Mail",
                  type: "email",
                  required: true,
                },
                {
                  name: "phone",
                  label: "Rückrufnummer",
                  type: "text",
                  required: false,
                },
                {
                  name: "message",
                  label: "Nachricht",
                  type: "text",
                  required: true,
                  component: "Textarea",
                  size: "100%",
                },
              ]}
            />
          </Flex>
        </Flex>
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;
