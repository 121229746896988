import React, { Component } from "react";
import styled from "styled-components";
import Textarea from "react-textarea-autosize";
import parse from "html-react-parser";
import Switch from "react-switch";

import Button from "./button";
import Input from "./input";
import { Text, Flex } from "../styled";

const StyledForm = styled.form`
  position: relative;
  width: 100%;

  button {
    background: ${(props) => props.theme.colors.primary};
    color: #fff;
    border: none;
  }

  .input-wrapper {
    position: relative;
    width: 100%;
    text-align: left;

    label {
      width: 100%;
      padding: 15px 0 3px 0;
      display: block;
      font-size: 0.8rem;
      font-weight: 400;
      letter-spacing: 1px;
      color: ${(p) => (p.error ? p.theme.colors.primary : p.theme.colors.text)};
      opacity: 1;
    }

    input,
    textarea {
      position: relative;
      outline: none;
      width: calc(100% - 10px);
      background: transparent;
      border: ${(props) =>
        props.error
          ? `2px solid ${props.theme.colors.error}`
          : "2px solid rgb(240,240,240)"};
      padding: 8px 13px;
      color: ${(props) => props.theme.colors.text};
      margin: 0;
      font-size: 0.9rem;
      -webkit-appearance: none;
      transition: 500ms border ease-in-out;

      &:focus {
        border-bottom: 2px solid ${(props) => props.theme.colors.primary};
      }

      &::placeholder {
        color: rgba(0, 0, 0, 0.3);
      }
    }

    textarea {
      vertical-align: middle;
    }
  }
`;

const encode = (data) =>
  Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join("&");

const Form = ({ schema, style }) => {
  const [ready, setReady] = React.useState(false);
  const [state, setState] = React.useState(() => {
    const s = {};
    schema.map((input, index) => {
      s[input.name] = "";
    });
    return s;
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ state });
    if (!ready) {
      alert("Bitte akzeptieren Sie die Datenverarbeitung");
    } else {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...state }),
      })
        .then(() => {
          alert("Danke für deine Nachricht");
          setState({});
        })
        .catch((error) => alert(error));
    }
  };

  const getInput = (input) => {
    switch (input.component) {
      case "Textarea": {
        return (
          <Textarea
            minRows={5}
            name={input.name}
            id={input.name}
            onChange={(e) => {
              const value = e.target?.value;
              setState((s) => ({ ...s, [input.name]: value }));
            }}
            required={input.required}
            placeholder={input.label}
          />
        );
      }
      default: {
        return (
          <Input
            type={input.type}
            name={input.name}
            id={input.name}
            value={state[input.name]}
            onChange={(name, value) => {
              setState((s) => ({ ...s, [name]: value }));
            }}
            required={input.required}
            placeholder={input.label}
          />
        );
      }
    }
  };
  return (
    <StyledForm
      onSubmit={handleSubmit}
      name="contact"
      method="POST"
      style={style}
      data-netlify="true"
    >
      <div className="input-wrapper" style={{ display: "none" }}>
        <label htmlFor={"bot-field"}>Don’t fill this out if youre human:</label>
        {getInput({
          name: "bot-field",
          label: "bot-field",
          type: "text",
          required: false,
        })}
      </div>
      <Flex row wrap>
        {schema.map((input, index) => (
          <div
            className="input-wrapper"
            style={{ width: input.size || "50%" }}
            key={input.name}
          >
            <label htmlFor={input.name}>
              {input.label} {input.required ? "*" : ""}
            </label>
            {getInput(input)}
            {input.footer ? parse(input.footer) : null}
          </div>
        ))}
      </Flex>
      <Text mt={20}>
        Wir benötigen für die Bearbeitung Ihrer Anfrage einige persönliche
        Angaben. Diese Felder sind entsprechend als Pflichtfelder eingerichtet
        (erforderlich). Wir verarbeiten und nutzen Ihre Daten ausschließlich für
        die Bearbeitung Ihrer Anfrage. Allgemeine Informationen zur
        Datenverarbeitung finden Sie in unserem{" "}
        <Button to="/datenschutzbestimmungen" p={0} clean>
          Datenschutzhinweis
        </Button>
        .
      </Text>
      <Text font="h5" mt={15}>
        Einverständniserklärung (erforderlich)
      </Text>
      <Flex mt={15} row>
        <Switch
          onChange={(checked) => setReady(checked)}
          checked={ready}
          uncheckedIcon={false}
          checkedIcon={false}
        />
        <Text
          style={{ cursor: "pointer" }}
          onClick={() => setReady(!ready)}
          ml={10}
        >
          Ja, ich bin mit der Verarbeitung meiner Daten einverstanden.
        </Text>
      </Flex>
      <Button as="button" type="submit" mt={20}>
        Senden
      </Button>
    </StyledForm>
  );
};

export default Form;
